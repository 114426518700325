var AWS = require('aws-sdk')

var region = 'us-east-1'
var aws_access_key_id = 'AKIAIFWUSWGH3RRB5XZQ'
var aws_secret_access_key = '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'

AWS.config = {
    "accessKeyId": aws_access_key_id,
    "secretAccessKey": aws_secret_access_key,
    "region": region,
    "sslEnabled": 'true'
}

//var endpoint = 'https://mturk-requester-sandbox.us-east-1.amazonaws.com'

// Uncomment this line to use in production
var endpoint = 'https://mturk-requester.us-east-1.amazonaws.com'

const mTurk = new AWS.MTurk({ endpoint: endpoint })


export default mTurk




/* import firebase from '@firebase/app'
import '@firebase/auth'
import '@firebase/firestore'

firebase.initializeApp({
  apiKey: 'AIzaSyBITbZ3jExQCxDlWGnllF8Y0_7681PNzVQ',
  authDomain: 'heedi-1141.firebaseapp.com',
  databaseURL: 'https://heedi-1141.firebaseio.com',
  projectId: 'heedi-1141'
  // storageBucket: 'heedi-1141.appspot.com',
  // messagingSenderId: '733999034905
})

export default firebase */