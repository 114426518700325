export const SET_HIT_TITLE = 'SET_HIT_TITLE'
export const SET_HIT_REWARD = 'SET_HIT_REWARD'
export const SET_HIT_ASSIGNMENT_NUMBER = 'SET_HIT_ASSIGNMENT_NUMBER'
export const SET_HIT_DESCRIPTION = 'SET_HIT_DESCRIPTION'
export const PUSH_HIT = 'PUSH_HIT'
export const RESET_HIT_LIST = 'RESET_HIT_LIST'
export const PUSH_ASSIGNMENT = 'PUSH_ASSIGNMENT'
export const RESET_ASSIGNMENT_LIST = 'RESET_ASSIGNMENT_LIST'
export const PUSH_CSV_ASSIGNMENT = 'PUSH_CSV_ASSIGNMENT'
export const RESET_CSV_ASSIGNMENT_LIST = 'RESET_CSV_ASSIGNMENT_LIST'
export const RESET_HIT = 'RESET_HIT'