<template>
<v-app>
    <v-container fluid>
        <v-layout column>
            <v-flex xs12 class="text-xs-center" mt-5>
                <v-bottom-sheet 
                    v-model="sheet"
                    >
                    <v-btn
                        slot="activator"
                        color="blue"
                        dark
                    >
                        Create Human Intelligence Task (HIT)
                    </v-btn>

                    <!-- Create Hit Form is Here -->
                    <form @submit.prevent="submitHit" ref="form">
                    <v-card tile>
                        <v-container fluid>
                        <v-layout row wrap>
                            <v-flex xs12 md4>
                                <v-text-field
                                    id="hitTitle"
                                    ref="hitTitle"
                                    label="Title"
                                    :rules="rules"
                                    required
                                    type="text"
                                    v-model="hitTitle">
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12 md4>
                                <v-text-field
                                    id="hitReward"
                                    ref="hitReward"
                                    label="Reward"
                                    :rules="rules"
                                    required
                                    type="text"
                                    v-model="hitReward">
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12 md4>
                                <v-text-field
                                    id="hitAssignmentNumber"
                                    ref="hitAssignmentNumber"
                                    label="# of Assignments"
                                    :rules="rules"
                                    required
                                    type="text"
                                    v-model="hitAssignmentNumber">
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex>
                                <v-text-field
                                    id="hitDescription"
                                    ref="hitDescription"
                                    label="Description"
                                    :rules="rules"
                                    required
                                    type="text"
                                    v-model="hitDescription">
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                            
                            <br /><br />
                        <v-layout row wrap>
                            <v-flex xs12 md3>
                                <v-btn
                                    color="grey"
                                    dark
                                    type="submit"
                                >
                                    Submit
                                </v-btn>
                            </v-flex>
                        </v-layout>    
                        </v-container>
                    </v-card>
                    </form>
                </v-bottom-sheet>&nbsp;&nbsp;
                <input type="file" v-on:change="handleFileSelect($event)" id="csv-file" name="files"/>
                
                
                
                
                
                <br /><br />
            </v-flex>
            <v-flex xs12 v-if="showHitList"><br />
                <v-data-table
                    :headers="headers"
                    :items="hits"
                    hide-actions
                    item-key="id"
                    ref="dTable"
                    disable-initial-sort
                >
                    <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
                    <template slot="items" slot-scope="props">
                        
                            <tr>
                            
                            <td class="text-xs-left" @click="showHit(props.item.HITId)">{{ props.item.Title }}</td>
                            <td class="text-xs-left" @click="showHit(props.item.HITId)">{{ props.item.Description }}</td>
                            <td class="text-xs-center" @click="showHit(props.item.HITId)">{{ props.item.MaxAssignments }}</td>
                            <!-- <td class="text-xs-center" @click="showHit(props.item.HITId)">{{ props.item.NumberofAssignmentsPending }}</td> -->
                            <td class="text-xs-center" @click="showHit(props.item.HITId)">{{ props.item.NumberOfAssignmentsCompleted }}</td>
                            <td class="text-xs-left" @click="showHit(props.item.HITId)">{{ props.item.HITStatus }}<br />{{ props.item.HITReviewStatus }}<br />{{ props.item.Expiration }}<br />{{ props.item.Reward }}</td>
                            <td class="text-xs-left"><span @click="expireHit(props.item.HITId)">
                                <i style="font-size: 1.2em;" class="fas fa-hourglass-end"></i> </span></td>
                            </tr>
                        
                    </template>
                </v-data-table>
            </v-flex>
            <v-flex xs12 v-else-if="showHitDetail">
                <a href="javascript:void(0);" v-on:click="switchViews()">Back to List</a>
               
                <v-data-table
                    :headers="assignmentHeaders"
                    :items="assignments"
                    hide-actions
                    item-key="id"
                    ref="dTable"
                    disable-initial-sort
                >
                    <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
                    <template slot="items" slot-scope="props">
                        
                            <tr>
                            
                            <td class="text-xs-left">
                                {{ processAnswer(props.item.Answer).answer1 }}<br />
                                {{ processAnswer(props.item.Answer).answer2 }}<br />
                                {{ processAnswer(props.item.Answer).answer3 }}<br /><br />
                                {{ processAnswer(props.item.Answer).answer4 }}<br />
                                {{ processAnswer(props.item.Answer).answer5 }}<br />
                                {{ processAnswer(props.item.Answer).answer6 }}<br /><br />
                                {{ processAnswer(props.item.Answer).answer7 }}<br />
                                {{ processAnswer(props.item.Answer).answer8 }}<br />
                            </td>
                            <td class="text-xs-left">
                                <span @click="approveThisAssignment(props.item.AssignmentId, props.item.Answer)" id="approveButton">
                                <v-icon large>check</v-icon> </span>
                                
                                
                                
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span @click="rejectThisAssignment(props.item.AssignmentId)" id="rejectButton">
                                <v-icon large>close</v-icon></span>




                            </td>
                            </tr>
                        
                    </template>
                </v-data-table>
            </v-flex>
            <v-flex xs12 v-else-if="showCSVDetail">
                <v-data-table
                    :headers="csvAssignmentHeaders"
                    :items="csv_assignments"
                    hide-actions
                    item-key="mean"
                    ref="dTable"
                    disable-initial-sort
                >
                    <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
                    <template slot="items" slot-scope="props">
                        
                            <tr>
                            
                            <td class="text-xs-left">
                                {{ props.item.mean }}<br />
                                {{ props.item.end }}<br />
                            </td>
                            <td class="text-xs-left">
                                <span @click="approveThisCSVAssignment(props.item)">
                                <v-icon large>check</v-icon> </span>
    

                            </td>
                            </tr>
                        
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</v-app>
</template>

<script>
//import { VBottomSheet } from 'vuetify/lib'
import '@/plugins/vuetify'
import hitModule from '@/store/hit'
import minderModule from '@/store/minder'
import statementModule from '@/store/statement'
import { mapGetters, mapActions } from 'vuex'
import xml2js from 'xml2js'
import papaparse from 'papaparse'

var data

export default {
  name: 'hitList',
  components: {
      //VBottomSheet
  },
  data: () => ({
    sheet: false,
    showHitList: true,
    showHitDetail: false,
    showCSVDetail: false,
    currentHitId: null,
    rules: [
        (v) => !!v || ' required'
    ],
    headers: [
        { text: 'Title', value: 'props.item.Title', align: 'left', sortable: false },
        { text: 'Desc', value: 'props.item.Description', align: 'left', sortable: false },
        { text: 'Total Assignments', value: 'props.item.MaxAssignments', align: 'left' },
        //{ text: '# Pending', value: 'props.item.NumberOfAssignmentsPending', align: 'left', sortable: false },
        { text: '# Completed', value: 'props.item.NumberOfAssignmentsCompleted', align: 'left', sortable: false },
        { text: 'Status', value: 'props.item.HITStatus', align: 'left' },
        { text: 'Actions', align: 'left'}
    ],
    assignmentHeaders: [
        { text: 'Answer', value: 'props.item.Answer', align: 'left'},
        { text: 'Actions', align: 'left'}
    ],
    csvAssignmentHeaders: [
        { text: 'Answer', value: 'props.item.mean', align: 'left'},
        { text: 'Actions', align: 'left'}
    ]
  }),
  computed: {
    ...mapGetters('hit', {
      hits: 'list',
      assignments: 'assignments',
      csv_assignments: 'csv_assignments'
    }),
    hitTitle: {
        set (value) {
            this.$store.commit('hit/SET_HIT_TITLE', { hitTitle: value }, { root: true })
        },
        get () {
            return this.$store.state.hit.newHit.hitTitle
        }
    },
    hitReward: {
        set (value) {
            this.$store.commit('hit/SET_HIT_REWARD', { hitReward: value }, { root: true })
        },
        get () {
            return this.$store.state.hit.newHit.hitReward
        }
    },
    hitAssignmentNumber: {
        set (value) {
            this.$store.commit('hit/SET_HIT_ASSIGNMENT_NUMBER', { hitAssignmentNumber: value }, { root: true })
        },
        get () {
            return this.$store.state.hit.newHit.hitAssignmentNumber
        }
    },
    hitDescription: {
        set (value) {
            this.$store.commit('hit/SET_HIT_DESCRIPTION', { hitDescription: value }, { root: true })
        },
        get () {
            return this.$store.state.hit.newHit.hitDescription
        }
    }
  },
  methods: {
    ...mapActions('hit', {
      listHits: 'getHitList',
      createHit: 'createHit',
      expireHit: 'expireHit',
      getAssignmentList: 'getAssignmentList',
      approveAssignment: 'approveAssignment',
      rejectAssignment: 'rejectAssignment',
      processCSVResults: 'processCSVResults'
    }),
    ...mapActions('minder', {
        createMinder: 'create',
        createMturkMinder: 'createMturkMinder',
        setIntentMeanStatement: 'setIntentMeanStatement',
        resetStatements: 'resetStatements',
        setEndStatement: 'setEndStatement',
        setValueStatement: 'setValueStatement',
        setStimulusText: 'setStimulusText'
    }),
    submitHit () {
        if(
            (this.$refs.hitTitle.validate() !== false) &&
            (this.$refs.hitReward.validate() !== false) &&
            (this.$refs.hitAssignmentNumber.validate() !== false) &&
            (this.$refs.hitDescription.validate() !== false)
        ) {
            this.sheet = false
            this.createHit()
        }
    },
    showHit (hitId) {
        this.getAssignmentList({hitId: hitId})
        this.showHitList = false
        this.showHitDetail = true
        this.currentHitId = hitId
    },
    switchViews () {
        this.showHitList = true
        this.showHitDetail = false
        this.currentHitId = null
    },
    processAnswer (xmlString) {
        var parsedAnswers = {}
        this.parseXMLAnswer(xmlString, (answer) => {
            parsedAnswers = answer
        })
        return parsedAnswers
    },
    parseXMLAnswer (answerXML, callbackFunction) {
        xml2js.parseString(answerXML, function (err, result) {
            var answer = result.QuestionFormAnswers
            if (answer) {
                answer = answer.Answer
                var parsedAnswer = {};
                for (var i=0; i < answer.length; i++) {
                    parsedAnswer[answer[i]["QuestionIdentifier"]] = answer[i]["FreeText"][0]
                }
                callbackFunction(parsedAnswer)
            } else {
                // no answer value yet
            } 
        })
    },
    approveThisAssignment (assignmentId, answer) {
        this.approveAssignment(assignmentId)

        //this.setMeanStatement(this.processAnswer(answer).answer1)
        //this.setEndStatement(this.processAnswer(answer).answer2)
        //this.setValueStatement(this.processAnswer(answer).answer3)
        var obj = {
            //m: this.processAnswer(answer).answer4,
            m: 'What behavior, or action, do you wish to start doing?',
            //e: this.processAnswer(answer).answer5,
            e: 'Why? What result are you trying to achieve?',
            //v: this.processAnswer(answer).answer6
            v: 'What happens if you DO NOT do this?'
        }
        var mturkMinderObj = {
            answer1: this.processAnswer(answer).answer1,
            answer2: this.processAnswer(answer).answer2,
            answer3: this.processAnswer(answer).answer3,
            answer7: this.processAnswer(answer).answer7
        }
        this.setStimulusText(obj)
        this.createMturkMinder(mturkMinderObj)
        //this.$router.push('/minders')
    },
    approveThisCSVAssignment (answer) {
        this.setIntentMeanStatement(answer.mean)
        this.setEndStatement(answer.end)
        this.setValueStatement("no value statement given")
        this.createMturkMinder()
        this.$router.push('/minders')
    },
    rejectThisAssignment (assignmentId) {
        this.rejectAssignment(assignmentId)
        this.switchViews()
    },
    handleFileSelect (evt) {
        var file = evt.target.files[0]
        papaparse.parse(file, {
            header: true,
            complete: (results) => {
                data = results
                this.processCSVResults(data)
            }
        })
        this.showHitList = false
        this.showCSVDetail = true
    }
  },
  beforeCreate () {
      if(!this.$store.state.hit) this.$store.registerModule('hit', hitModule)
      if(!this.$store.state.minder) this.$store.registerModule('minder', minderModule)
      if(!this.$store.state.statement) this.$store.registerModule('statement', statementModule)
  },
  created() {
      this.listHits()
  },
  mounted() {

  },
  updated() {

  },
  watch: {

  }
}
</script>

<style>


#approveButton {
  cursor: pointer;
}

#rejectButton {
  cursor: crosshair;
}

</style>
