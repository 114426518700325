import mTurk from '@/aws'

import {
    SET_HIT_TITLE,
    SET_HIT_REWARD,
    SET_HIT_ASSIGNMENT_NUMBER,
    SET_HIT_DESCRIPTION,
    RESET_HIT,
    PUSH_HIT,
    RESET_HIT_LIST,
    PUSH_ASSIGNMENT,
    RESET_ASSIGNMENT_LIST,
    PUSH_CSV_ASSIGNMENT,
    RESET_CSV_ASSIGNMENT_LIST
} from './mutations'


const state = {
    list: [],
    assignments: [],
    csv_assignments: [],
    newHit: {
        hitTitle: null,
        hitReward: null,
        hitAssignmentNumber: null,
        hitDescription: null
    }
    
}

const getters = {
    list: ({list}) => list,
    assignments: ({assignments}) => assignments,
    csv_assignments: ({csv_assignments}) => csv_assignments
}

const mutations = {
    [SET_HIT_TITLE] (state, payload) {
        state.newHit.hitTitle = payload.hitTitle
    },
    [SET_HIT_REWARD] (state, payload) {
        state.newHit.hitReward = payload.hitReward
    },
    [SET_HIT_ASSIGNMENT_NUMBER] (state, payload) {
        state.newHit.hitAssignmentNumber = payload.hitAssignmentNumber
    },
    [SET_HIT_DESCRIPTION] (state, payload) {
        state.newHit.hitDescription = payload.hitDescription
    },
    [RESET_HIT] (state) {
        state.newHit.hitTitle = null
        state.newHit.hitReward = null
        state.newHit.hitAssignmentNumber = null
        state.newHit.hitDescription = null
    },
    [PUSH_HIT] (state, hit) {
        state.list.push(hit)
    },
    [RESET_HIT_LIST] (state) {
      state.list = []
    },
    [PUSH_ASSIGNMENT] (state, assignment) {
        state.assignments.push(assignment)
    },
    [RESET_ASSIGNMENT_LIST] (state) {
      state.assignments = []
    },
    [PUSH_CSV_ASSIGNMENT] (state, assignment) {
        state.csv_assignments.push(assignment)
    },
    [RESET_CSV_ASSIGNMENT_LIST] (state) {
      state.csv_assignments = []
    }
}

const actions = {
    async createHit ({state, dispatch}) {
        // TODO:  complete
        var xmlString = "<?xml version=\"1.0\" encoding=\"UTF-8\"?><ExternalQuestion xmlns=\"http://mechanicalturk.amazonaws.com/AWSMechanicalTurkDataSchemas/2006-07-14/ExternalQuestion.xsd\"><ExternalURL>https://heedi.com/mturk</ExternalURL><FrameHeight>0</FrameHeight></ExternalQuestion>"
        var myQuestion = xmlString.trim().replace("[^\\x20-\\x7e]", "")  //This was a magic line that helped all this pop into place
        var myHIT = {
            Title: state.newHit.hitTitle,
            Description: state.newHit.hitDescription,
            MaxAssignments: state.newHit.hitAssignmentNumber,
            LifetimeInSeconds: 4233600,
            AssignmentDurationInSeconds: 1020,
            Reward: state.newHit.hitReward,
            Question: myQuestion,
        };
        

        await mTurk.createHIT(myHIT, function (err, data) {
            if (err) {
                /* eslint-disable no-console */
                console.log(err.message);
            } else {
                /* eslint-disable no-console */
                console.log('HIT has been successfully published here: https://worker.mturk.com/mturk/preview?groupId=' + data.HIT.HITTypeId + ' with this HITId: ' + data.HIT.HITId);
                dispatch('getHitList')
            }
        })

    },
    async getHitList ({commit}) {
        //
        await mTurk.listHITs({}, function(err, hitList) {
            if (err) {
                /* eslint-disable no-console */
                console.log(err.message)
            } else {
                commit(RESET_HIT_LIST)
                //for each commit to the list
                hitList.HITs.forEach((hit) => {
                    commit(PUSH_HIT, {
                    ...hit
                    })
                })


            }
        })
    },
    async expireHit(_, id) {
        await mTurk.updateExpirationForHIT({HITId: id, ExpireAt: 0}, function(err) {
            if (err) {
                /* eslint-disable no-console */
                console.log("error"+err.message)
            } else {
                /* eslint-disable no-console */
                console.log("HIT - "+id+" has been expired!")
            }
        })
    },
    async getAssignmentList ({commit}, data) {
        var statuses = ['Submitted']
        await mTurk.listAssignmentsForHIT({HITId: data.hitId, AssignmentStatuses: statuses, MaxResults: 100}, function(err, assignmentsForHIT) {
            if (err) {
                /* eslint-disable no-console */
                console.log("error"+err.message)
            } else {
                commit(RESET_ASSIGNMENT_LIST)
                //for each commit to the list
                assignmentsForHIT.Assignments.forEach((assignment) => {

                    commit(PUSH_ASSIGNMENT, {
                    ...assignment
                    })
                })
            }
        })
    },
    processCSVResults({commit}, data){
        commit(RESET_CSV_ASSIGNMENT_LIST)
        data.data.forEach((csv_assignment) => {
            commit(PUSH_CSV_ASSIGNMENT, {
                ...csv_assignment
            })
        })
    },
    async approveAssignment (_,data) {
        await mTurk.approveAssignment({AssignmentId: data}, function(err, result) {
            if (err) {
                /* eslint-disable no-console */
                console.log("Approve assignment error: "+err.message)
            }
            /* eslint-disable no-console */
            console.log("Result of approval: "+result)
        })
    },
    async rejectAssignment (_,data) {
        await mTurk.rejectAssignment({AssignmentId: data, RequesterFeedback: 'Appreciate your effort, but the directions were not followed.'}, function(err) {
            if (err) {
                /* eslint-disable no-console */
                console.log("Reject assignment error: "+err.message)
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }